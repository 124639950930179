$color-variable-example: crimson;
$text-cariable-example: "All Hail The Crimson King";

//$main-font: "IBM Plex Sans", Helvetica, Arial, sans-serif;
$main-font: "Verdana", Arial, sans-serif;

//colors
$main-color: #171219;
$white: #fff;
$black: #171219;
$gray: #7F7F7F;
$grey-light: #9F9F9F;
$primary-color: #005B4A;
$blue-color: #97c9e8;
$violet-color: #A700F5;
$decorative-color: #F76800;

$wrapper-bg: #F2FFFD;
$promo-bg: $primary-color;
$promo-text: $white;
$promo-link: $white;
$promo-circle: $blue-color;
$mirror-link-color: $blue-color;
$product-flag: #598474;
$panel-item-color: #FDFDFD;

$post-category-color: $violet-color;
$post-arrow-color: $violet-color;
$post-arrow-color-hover: $white;
$post-wrapper-bg-active: #005343;

$product-text-color: $primary-color;

$btn-text-transform: uppercase;
$primary-button-color: $violet-color;
$primary-button-color-hover: #9501DA;
$primary-button-border-color-hover: $primary-color;

$secondary-button-color: #F500DD;
$secondary-button-color-hover: #CD07B9;

$active-color: $violet-color;
$secondary-active-color: #97C9E8;
$image-bg: $primary-color;
$step-bio-color: $primary-color;
$post-img-color: $primary-color;
$post-wrapper-bg-hover: $primary-color;
$post-title: $black;
$post-title-hover: $white;
$product-banner-color: $primary-color;
$header-border-bottom: 1px solid rgba(127, 127, 127, 0.5);
$checked-list-justify: center;
$breadcrumbs-icon: '../img/play-arrow.svg';
$quiz-link-btn-padding: 16px 18px;
$pages-link-width: 325px;
$links-weight: 500;
$links-color: black;
$interactive-text-align: center;
$btn-bd-radius: 0;
$btn-bd-primary: unset;
$btn-bd-secondary: 1px solid #97C9E8;
$circle-color: $primary-color;
$interactive-right-bg: #97C9E8;
$interactive-bg-color: transparent;
$interactive-img-radius: 44px;
$post-item-radius: 0;
$steps-bio-radius: 0;
$quiz-b-radius: 0;
$quiz-shadow: none;
$secondary-text-color: white;
$secondary-btn-posX-mob: auto;

$footer-bg: #F2F6F6;
$bio-shadow-img: "../img/bio-shadow.png";
$quiz-shadow-img: "../img/quiz-shadow.png";
$faq-bg: #F2F6F6;
$faq-shadow: none;
$faq-b-radius: 0;
$faq-color: #666666;
$quiz-img-b-color: #F2F6F6;
$nav-bg: $wrapper-bg;
$post-category-color-hover: white;
$post-wrapper-bg: #F2F6F6;

@mixin header {}

@mixin footer {}

@mixin posts {}

@mixin links {
    .links {
        @include rm(640) {
            margin-bottom: 30px;
        }

        @include rm(768) {
            margin-bottom: 0;
        }
    }

    .links__link {
        @include rm(640) {
            max-width: 200px;
        }
    }

    .links__link.active {
        position: relative;
        font-weight: bold;

        &:before {
            position: absolute;
            content: "";
            top: 50%;
            left: -4px;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            background: url($breadcrumbs-icon) no-repeat;
            background-size: 100%;
        }
    }
}

@mixin navigation {
    .nav__wrapper {
        @include rm(768) {
            flex-wrap: wrap;
            max-width: 100%;
            justify-content: space-between;
        }
    }
}

@mixin pages-links {
    .pages__wrapper {
        width: 100%;
        margin-bottom: 10px;
        padding-bottom: 10px;

        &:before {
            position: absolute;
            content: "";
            width: 100vw;
            height: 1px;
            background: rgba(127, 127, 127, .5);
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

@mixin quiz-link {
    .quiz-link__btn {
        @include rm(360) {
            padding: 16px 30px;
        }
    }
}

@mixin blog {}

@mixin breadcrumbs {}

@mixin checked {
    .checked {
        @include rm(640) {
            padding-top: 40px;
            padding-bottom: 30px;
        }
    }

    .checked-list {
        @include rm(640) {
            margin: 0 auto;
        }
    }
}

@mixin quiz {
    .interactive:after {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        content: "🔎 Find the Platform";
        color: #fff;
        top: -15px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        padding-bottom: 4px;
        transform: translateX(-50%);
        text-align: center;
        clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 52% 90%, 50% 100%, 48% 90%, 0 90%);
        background-color: $decorative-color;
        line-height: 1.2;
        font-size: 16px;
        width: 188px;
        height: 49px;
        z-index: 3;
    }
}

@mixin promo {}

@mixin catalog {}

@mixin faq {}

@mixin product {

    .asiavibe {
        .product-banner:before {
            content: '2';
        }
    }

    .euromeets {
        .product-banner:before {
            content: '3';
        }
    }

    .juliettdate {
        .product-banner:before {
            content: '4';
        }
    }
}

@mixin text-tooltip {}
